.icon {
  transition: opacity 0s ease-in, transform 0.1s ease-in;
}

.icon.open {
  opacity: 1;
  transform: rotate(180deg);
}

.icon.revopen {
  opacity: 1;
  transform: rotate(-180deg);
}

